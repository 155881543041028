import React, {createContext, useContext} from 'react'
import Link from 'gatsby-link'
import LocalStorage from './local-storage'
import {isBrowserEnvironment} from './misc'
import {DEFAULT_LOCALE, LOCALES} from './constants'

export const LocaleContext = createContext(DEFAULT_LOCALE)

export const LocaleProvider = LocaleContext.Provider

export function useLocale () {

  const locale = useContext(LocaleContext)

  return locale || DEFAULT_LOCALE

}

export function LocaleLink ({className, to, children}) {

  const locale = useLocale()

  const prefix = locale == DEFAULT_LOCALE ? '' : `/${locale}`

  return (
    <Link to={prefix + to} className={className}>
      {children}
    </Link>
  )
}

export function localiseObject (obj) {

  const locale = useLocale()

  if (obj[locale]) {

    return obj[locale]

  } else if (obj[DEFAULT_LOCALE]) {

    console.warn(`Locale: Falling back to default locale for message: ${obj[DEFAULT_LOCALE]}`)

    return obj[DEFAULT_LOCALE]

  } else {

    const fallback = Object.values(obj).splice(0, 1)

    console.error(`Locale: No locale version for message: ${fallback}`)

    return fallback

  }
}

export const localiseMessage = localiseObject

export function getPrefferedLocaleFromBrowser () {

  const language = isBrowserEnvironment() ? navigator.language : undefined

  return language ? language.slice(0, 2).toLowerCase() : DEFAULT_LOCALE

}

// Should we show the user our locale picker?
export function shouldShowLocaleModal ({currentLocale}) {

  const localStorage = LocalStorage()
  const prefferedLocale = getPrefferedLocaleFromBrowser()

  // is not current locale
  const isNotCurrent = currentLocale != prefferedLocale

  // is the locale one of the languages that we can offer?
  const localeExists = !!LOCALES.find(locale => currentLocale == locale)

  // can we be sure we haven't already asked them for their preffered locale?
  const alreadySet = !localStorage.isSupported() || localStorage.get('hasSetLocale')

  return localeExists && isNotCurrent && !alreadySet

}

export function setLocale ({fromLocale, toLocale}) {

  const localStorage = LocalStorage()

  localStorage.set('hasSetLocale', true)

  if (fromLocale != toLocale)
    changePageLocale ({fromLocale, toLocale})

}

export function changePageLocale ({fromLocale, toLocale}) {

  if (isBrowserEnvironment()) {

    // Probably a better way of doing this?
    let {origin, pathname, search} = window.location
    const pathparts = pathname.split('/').filter(part => part).filter((part, i) => i || part != fromLocale)
    const toLocalePart = toLocale == DEFAULT_LOCALE ? [] : [toLocale]
    const newHref = `${[origin].concat(toLocalePart).concat(pathparts).join('/')}${search}`

    window.location = newHref

  }
}

export function getLocaleOfUrl ({url, fromLocale, toLocale}) {

  const pathArray = url.split('/')
  const protocol = pathArray[0]
  const host = pathArray[2]
  const origin = protocol + host
  const pathparts = pathArray.slice(3).filter(part => part).filter((part, i) => i || part != fromLocale)
  const toLocalePart = toLocale == DEFAULT_LOCALE ? [] : [toLocale]

  return `${[origin].concat(toLocalePart).concat(pathparts).join('/')}`

}

export function getLanguageFromLocale (locale) {

  return {

    en: 'English',
    fr: 'Français'

  }[locale]
}
