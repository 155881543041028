import {isBrowserEnvironment} from './misc'

export default function LocalStorage () {

  const localStorageSupported = supported('localStorage')

  function get (key, defaultValue) {

    const defaultValueProvided = arguments.length > 1

    if (localStorageSupported) {

      const value = isBrowserEnvironment() ? window.localStorage.getItem(key) : null

      return value === null && defaultValueProvided ? defaultValue : value

    } else {

      return defaultValueProvided ? defaultValue : null

    }
  }

  function set (key, value) {

    if (localStorageSupported)
      window.localStorage.setItem(key, value)

  }

  function unset (key) {

    if (localStorageSupported)
      window.localStorage.removeItem(key)

  }

  function isSupported () {

    return localStorageSupported

  }

  // from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
  function supported (type) {

    if (isBrowserEnvironment()) {

      let storage = window[type]

      try {

        let x = '__storage_test__'

        storage.setItem(x, x)
        storage.removeItem(x)

        return true

      } catch(e) {

        return e instanceof DOMException && (e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') && storage.length !== 0

      }
    }
  }

  return {get, set, unset, isSupported}

}
