export const LOCALES = process.env.GATSBY_LOCALES ? process.env.GATSBY_LOCALES.replace(/\s/g, '').split(',') : ['en']
export const DEFAULT_LOCALE = LOCALES[0]
export const SITE_URL = 'https://socialvend.com'
export const CONTACT_EMAIL = 'hello@socialvend.com'
export const CONTACT_NUMBER = '+44 (0)20 3920 6684'
export const US_CONTACT_NUMBER = '+1 (212) 389 2867'

export const VENDOS_URLS = {

  LANDING: 'https://vendos.io/',
  LOGIN: 'https://vendos.io/'

}

export const PAGE_URLS = {

  CASESTUDIES: 'casestudies'

}

export const SOCIAL_HANDLES = {

  FACEBOOK: 'socialvend',
  TWITTER: 'socialvend',
  INSTAGRAM: 'social_vend'

}

export const SOCIAL_URLS = {

  FACEBOOK: `https://www.facebook.com/${SOCIAL_HANDLES.FACEBOOK}`,
  TWITTER: `https://twitter.com/${SOCIAL_HANDLES.TWITTER}`,
  INSTAGRAM: `https://www.instagram.com/${SOCIAL_HANDLES.INSTAGRAM}`

}
