import React from 'react'
import Helmet from 'react-helmet'
import {LocaleProvider} from './src/helpers/locale'
import {DEFAULT_LOCALE} from './src/helpers/constants'

export const wrapPageElement = ({element, props: {pageContext}}) => {

  const {locale} = pageContext || {locale: DEFAULT_LOCALE}

  return (
    <LocaleProvider value={locale}>
      <Helmet htmlAttributes={{lang: locale}}/>
      {element}
    </LocaleProvider>
  )
}
