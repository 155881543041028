export function makeArray (num, fillWith) {

  if (num < 1) return []

  let arr = Array.apply(null, Array(num))

  if (fillWith)
    arr = arr.map(() => fillWith)

  return arr

}

export function realModulo (n1, n2) {

  return ((n1 % n2) + n2) % n2

}

export function isBrowserEnvironment () {

  return typeof window !== `undefined`

}
